import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { Observable, map } from "rxjs";
import { urlBuilder } from "@zixi/shared-utils";
import { Breadcrumb } from "src/app/services/breadcrumbs.service";
import { TranslateService } from "@ngx-translate/core";
import { ChannelsService } from "./channels.service";
import { AdaptiveChannel, DeliveryChannel, FailoverChannel, MediaLiveChannel } from "./channel";
import { MediaConnectFlow } from "src/app/models/shared";

@Injectable({
    providedIn: "root"
})
export class ChannelBreadcrumbsResolverService implements Resolve<Breadcrumb[]> {
    constructor(private channelsService: ChannelsService, private translate: TranslateService) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Breadcrumb[]> | Observable<never> {
        if (route.params["type"] === "pass-through") {
            return this.channelsService
                .refreshDeliveryChannel(urlBuilder.decode(route.params["channelId"]), false, false)
                .pipe(
                    map((channel: DeliveryChannel) => [
                        {
                            label: channel.name,
                            url: state.url
                        }
                    ])
                );
        } else if (route.params["type"] === "adaptive" || route.params["type"] === "transcode") {
            return this.channelsService
                .refreshAdaptiveChannel(urlBuilder.decode(route.params["channelId"]), false, false)
                .pipe(
                    map((channel: AdaptiveChannel) => [
                        {
                            label: channel.name,
                            url: state.url
                        }
                    ])
                );
        } else if (route.params["type"] === "failover") {
            return this.channelsService
                .refreshFailoverChannel(urlBuilder.decode(route.params["channelId"]), false, false)
                .pipe(
                    map((channel: FailoverChannel) => [
                        {
                            label: channel.name,
                            url: state.url
                        }
                    ])
                );
        } else if (route.params["type"] === "mediaconnect") {
            return this.channelsService
                .refreshMediaConnectFlow(urlBuilder.decode(route.params["channelId"]), false)
                .pipe(
                    map((channel: MediaConnectFlow) => [
                        {
                            label: channel.name,
                            url: state.url
                        }
                    ])
                );
        } else if (route.params["type"] === "medialive") {
            return this.channelsService
                .refreshMediaLiveChannel(urlBuilder.decode(route.params["channelId"]), false)
                .pipe(
                    map((channel: MediaLiveChannel) => [
                        {
                            label: channel.name,
                            url: state.url
                        }
                    ])
                );
        } else {
            //
        }
    }
}

@Injectable({
    providedIn: "root"
})
export class ChannelFormBreadcrumbsResolverService implements Resolve<Breadcrumb[]> {
    constructor(private channelsService: ChannelsService, private translate: TranslateService) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Breadcrumb[]> | Observable<never> {
        if (route.url[1].path === "pass-through") {
            return this.channelsService
                .refreshDeliveryChannel(urlBuilder.decode(route.params["channelId"]), false, false)
                .pipe(
                    map((channel: DeliveryChannel) => [
                        {
                            label: this.translate.instant("CHANNELS"),
                            url: "/" + route.url[0].path
                        },
                        {
                            label: channel.name,
                            url:
                                "/" +
                                route.url[0].path +
                                "/" +
                                route.url[1].path +
                                "/" +
                                route.url[2].path +
                                "/" +
                                route.url[3].path
                        },
                        {
                            label:
                                (route.params.action
                                    ? this.translate.instant(route.params.action.toUpperCase()) + " "
                                    : "") + this.translate.instant("CHANNEL")
                        }
                    ])
                );
        } else if (route.url[1].path === "adaptive" || route.url[1].path === "transcode") {
            return this.channelsService
                .refreshAdaptiveChannel(urlBuilder.decode(route.params["channelId"]), false, false)
                .pipe(
                    map((channel: AdaptiveChannel) => [
                        {
                            label: this.translate.instant("CHANNELS"),
                            url: "/" + route.url[0].path
                        },
                        {
                            label: channel.name,
                            url:
                                "/" +
                                route.url[0].path +
                                "/" +
                                route.url[1].path +
                                "/" +
                                route.url[2].path +
                                "/" +
                                channel.name
                        },
                        {
                            label:
                                (route.params.action
                                    ? this.translate.instant(route.params.action.toUpperCase()) + " "
                                    : "") + this.translate.instant("CHANNEL")
                        }
                    ])
                );
        } else if (route.url[1].path === "failover") {
            return this.channelsService
                .refreshFailoverChannel(urlBuilder.decode(route.params["channelId"]), false, false)
                .pipe(
                    map((channel: FailoverChannel) => [
                        {
                            label: this.translate.instant("CHANNELS"),
                            url: "/" + route.url[0].path
                        },
                        {
                            label: channel.name,
                            url:
                                "/" +
                                route.url[0].path +
                                "/" +
                                route.url[1].path +
                                "/" +
                                route.url[2].path +
                                "/" +
                                channel.name
                        },
                        {
                            label:
                                (route.params.action
                                    ? this.translate.instant(route.params.action.toUpperCase()) + " "
                                    : "") + this.translate.instant("CHANNEL")
                        }
                    ])
                );
        } else if (route.url[1].path === "mediaconnect") {
            return this.channelsService
                .refreshMediaConnectFlow(urlBuilder.decode(route.params["channelId"]), false)
                .pipe(
                    map((channel: MediaConnectFlow) => [
                        {
                            label: this.translate.instant("CHANNELS"),
                            url: "/" + route.url[0].path
                        },
                        {
                            label: channel.name,
                            url:
                                "/" +
                                route.url[0].path +
                                "/" +
                                route.url[1].path +
                                "/" +
                                route.url[2].path +
                                "/" +
                                channel.name
                        },
                        {
                            label:
                                (route.params.action
                                    ? this.translate.instant(route.params.action.toUpperCase()) + " "
                                    : "") + this.translate.instant("CHANNEL")
                        }
                    ])
                );
        } else if (route.url[1].path === "medialive") {
            return this.channelsService
                .refreshMediaLiveChannel(urlBuilder.decode(route.params["channelId"]), false)
                .pipe(
                    map((channel: MediaLiveChannel) => [
                        {
                            label: this.translate.instant("CHANNELS"),
                            url: "/" + route.url[0].path
                        },
                        {
                            label: channel.name,
                            url:
                                "/" +
                                route.url[0].path +
                                "/" +
                                route.url[1].path +
                                "/" +
                                route.url[2].path +
                                "/" +
                                channel.name
                        },
                        {
                            label:
                                (route.params.action
                                    ? this.translate.instant(route.params.action.toUpperCase()) + " "
                                    : "") + this.translate.instant("CHANNEL")
                        }
                    ])
                );
        } else {
            //
        }
    }
}
