import { Component, OnInit, OnDestroy } from "@angular/core";
import { Location } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { take } from "rxjs/operators";
import * as _ from "lodash";

import { Constants } from "../../../constants/constants";

import { TranslateService } from "@ngx-translate/core";
import { SharedService } from "src/app/services/shared.service";
import { ClustersService } from "../../clusters/clusters.service";
import { ChannelsService } from "../channels.service";
import { BroadcastersService } from "../../../components/broadcasters/broadcasters.service";
import { SourcesService } from "../../sources/sources.service";

import { Source, MediaConnectFlow, Bitrate } from "src/app/models/shared";
import { AdaptiveChannel, DeliveryChannel } from "../channel";
import { ModalService } from "../../../components/shared/modals/modal.service";
import { MixpanelService } from "src/app/services/mixpanel.service";
import { TranscodingProfile } from "../../transcoding-profiles/transcoding-profile";
import { TitleService } from "../../../services/title.service";
import { UntypedFormControl, Validators } from "@angular/forms";
import { urlBuilder } from "@zixi/shared-utils";

@Component({
    selector: "app-channel-adaptive-form",
    templateUrl: "./channel-form.component.html"
})
export class ChannelFormAdaptiveComponent implements OnInit, OnDestroy {
    channel: AdaptiveChannel;
    channels: (AdaptiveChannel | DeliveryChannel | MediaConnectFlow)[];
    channelId: number;
    channelName: string;
    channelNames: string[];
    channelCluster: string;
    existingChannel: AdaptiveChannel;

    objectType: string = null;
    objectCluster: string = null;
    objectName: string = null;

    action: string;
    loading = true;
    saving = false;
    adaptiveSourceFilter: string;
    showAdvanced = false;

    submitted = false;
    minLength = 2;
    isEdit = false;
    isClone = false;
    startDisabled = false;
    constants = Constants;

    page = 1;
    pageSize = 10;

    sources: Source[] = [];
    sourcesLoading = false;
    selectedBitrates: {
        profile?: TranscodingProfile;
        profile_id: number;
        source?: Source;
        source_id: number;
        name: string;
        kbps: number;
    }[] = [];

    targetBXsLoading = false;
    targetBXs = [
        { name: this.translate.instant("PREFER_PRIMARY_BROADCASTERS"), id: -1, cluster: null },
        { name: this.translate.instant("PRIMARY_BROADCASTERS_ONLY"), id: -2 },
        { name: this.translate.instant("BACKUP_BROADCASTERS_ONLY"), id: -3 },
        { name: this.translate.instant("PREFER_BACKUP_BROADCSTER"), id: -4, cluster: null }
    ];
    targetBXsBase = this.targetBXs;

    clusterDNSPrefix: string;

    private channelsSubscription: Subscription;
    autoPullDisabled: boolean;
    tagsControl = new UntypedFormControl([], [Validators.required]);
    nameControl = new UntypedFormControl("", [
        Validators.required,
        Validators.minLength(2),
        Validators.pattern(Constants.validators.source_name),
        Validators.pattern(Constants.validators.no_blanc_start_or_end)
    ]);
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private translate: TranslateService,
        private clusterService: ClustersService,
        private sharedService: SharedService,
        private broadcastersService: BroadcastersService,
        private sourcesService: SourcesService,
        private modalService: ModalService,
        private cs: ChannelsService,
        private mixpanelService: MixpanelService,
        private titleService: TitleService,
        private location: Location
    ) {
        // The ActivatedRoute dies with the routed component and so the subscription dies with it.
        this.route.paramMap.subscribe(async params => {
            this.channelId = urlBuilder.decode(params.get("channelId"));
            this.channelName = params.get("name");
            this.channelCluster = params.get("cluster");
            this.action = params.get("action");

            if (params.get("objecttype")) this.objectType = params.get("objecttype");
            if (params.get("objectcluster")) this.objectCluster = params.get("objectcluster");
            if (params.get("objectname")) this.objectName = params.get("objectname");

            if (this.channelName && this.channelId) {
                this.channel = Object.assign({}, this.cs.getCachedAdaptiveChannel(this.channelId));
                this.existingChannel = _.cloneDeep(this.channel);

                // Check if channel found in cache, if not get channels and channel
                if (this.sharedService.isEmptyObject(this.channel) || !this.channel.hasFullDetails) {
                    await this.cs.getAdaptiveChannels().toPromise();
                    this.channel = Object.assign({}, this.cs.getCachedAdaptiveChannel(this.channelId));

                    const channel = await this.cs.getAdaptiveChannel(this.channel.id);
                    if (channel) this.channel = Object.assign({}, channel);

                    this.existingChannel = _.cloneDeep(this.channel);
                    this.loading = false;
                } else {
                    this.loading = false;
                }
            } else {
                this.loading = false;
            }
            this.prepForm();
        });
    }

    prepForm() {
        if (this.action) {
            this.tagsControl.setValue(this.channel.resourceTags);
            if (this.action === "edit") {
                this.nameControl.setValue(this.channel.name);
                this.isEdit = true;
            } else if (this.action === "clone") {
                this.isClone = true;
                this.channel.name = "";
                this.channel.muted = this.channel.active_mute ? 1 : 0;
            }

            if (this.channel) {
                this.channel = this.channel as AdaptiveChannel;

                if (this.channel.broadcaster_id == null) this.channel.broadcaster_id = -1;

                if (this.channel.bitrates && this.channel.bitrates.length > 0) {
                    this.selectedBitrates = this.channel.bitrates;
                    this.sources = _.filter(this.sources, s => {
                        return !_.find(this.selectedBitrates, b => {
                            return b.source_id === s.id;
                        });
                    });
                }

                if (this.channel.broadcaster_cluster_id !== null) {
                    this.getClusterChannelNames(this.channel.broadcaster_cluster_id);
                    this.getTargetBroadcasters(this.channel.broadcaster_cluster_id);
                    this.getClusterDNSPrefix(this.channel.broadcaster_cluster_id);
                }
            }
        }

        if (!this.channel && !this.isClone && !this.isEdit) {
            this.resetForm();
        }

        // Set Title
        this.titleService.setTitle("CHANNEL", this.action, this.channel);
    }

    resetForm() {
        this.tagsControl.setValue([]);
        this.nameControl.setValue(null);

        // Channel
        this.channel = new AdaptiveChannel();
        // UI
        this.selectedBitrates = [];

        this.channel.segment_on_scte35 = 1;
        this.channel.skip_cue_in = 0;
        this.channel.drop_first_segment = 1;
        this.channel.timecode_mode = "system";
        this.channel.disable_cmaf = true;
        this.channel.disable_hls = false;
        this.channel.disable_synchronization = false;
        this.channel.strict_chunks = false;
        this.channel.support_multiaudio = false;
        this.channel.alt_channel_id = null;
    }

    ngOnInit() {
        // Channels
        this.cs.getAdaptiveChannels();
        this.cs.getDeliveryChannels();
        this.channelsSubscription = this.cs.channels.subscribe(
            (channels: (AdaptiveChannel | DeliveryChannel | MediaConnectFlow)[]) => {
                this.channels = channels;
                this.getClusterChannelNames(this.channel.broadcaster_cluster_id);
            }
        );

        // Sources
        this.sourcesLoading = true;
        this.sourcesService.refreshSources(true);
        this.sourcesService.sources.pipe(take(1)).subscribe((sources: Source[]) => {
            if (this.objectType && this.objectCluster && this.objectName) {
                const source = _.find(sources, s => {
                    return s.name === this.objectName && s.inputCluster.dns_prefix === this.objectCluster;
                });

                if (source) {
                    this.selectAdaptiveSource(source);

                    if (source.broadcaster_cluster_id) {
                        this.channel.broadcaster_cluster_id = source.broadcaster_cluster_id;
                        this.getClusterChannelNames(this.channel.broadcaster_cluster_id);
                        this.getTargetBroadcasters(this.channel.broadcaster_cluster_id);
                        this.getClusterDNSPrefix(this.channel.broadcaster_cluster_id);
                    }

                    if (source.target_broadcaster_id) this.channel.broadcaster_id = source.target_broadcaster_id;
                }
            }

            this.sources = sources;
            this.sources = _.filter(sources, s => {
                return !_.find(this.selectedBitrates, b => {
                    return b.source_id === s.id;
                });
            });
            this.sourcesLoading = false;
        });

        this.prepForm();
    }

    ngOnDestroy() {
        this.channelsSubscription.unsubscribe();
    }

    async onSubmit() {
        this.saving = true;

        if (
            this.selectedBitrates.length === 0 ||
            this.autoPullCheck() ||
            (this.channel.disable_cmaf && this.channel.disable_hls)
        ) {
            this.saving = false;
            return;
        }

        if (this.channel.adaptive) this.getClusterDNSPrefix(this.channel.broadcaster_cluster_id);

        const model = {
            name: this.nameControl.value,

            resource_tag_ids: _.map(this.tagsControl.value, "id"),
            alerting_profile_id: this.channel.alertingProfile.id,
            broadcaster_cluster_id: this.channel.broadcaster_cluster_id,
            type: "adaptive",
            broadcaster_id: this.channel.broadcaster_id,
            webvtt_cc: this.channel.webvtt_cc,
            hls_chunks: this.channel.hls_chunks,
            hls_chunk_duration: this.channel.hls_chunk_duration,
            is_transcoding: 0,
            log_scte: this.channel.log_scte,
            is_source_included: 0,

            segment_on_scte35: this.channel.segment_on_scte35,
            skip_cue_in: this.channel.skip_cue_in,
            drop_first_segment: this.channel.drop_first_segment,

            timecode_mode: this.channel.timecode_mode,
            bitrates: _.map(this.selectedBitrates, bitrate =>
                _.pick(bitrate, "profile_id", "source_id", "name", "kbps")
            ),
            disable_hls: this.channel.disable_hls,
            disable_synchronization: this.channel.disable_synchronization,
            strict_chunks: this.channel.strict_chunks,
            muted: this.channel.muted,
            is_enabled:
                !this.isEdit && this.startDisabled
                    ? 0
                    : !this.isEdit && !this.startDisabled
                    ? 1
                    : this.channel.is_enabled,
            disable_cmaf: this.channel.disable_cmaf,
            support_multiaudio: this.channel.support_multiaudio,
            alt_channel_id: this.channel.alt_channel_id
        };

        if (this.isEdit) {
            const changedData = this.sharedService.getZixiObjDiff(model, this.existingChannel, []);
            const newBitrates = this.compareBitrates(this.existingChannel, model);
            if (!newBitrates) {
                delete changedData["bitrates"];
            } else {
                Object.assign(changedData, { bitrates: newBitrates });
            }
            const isEmptyData = this.sharedService.isEmptyObject(changedData);

            if (!isEmptyData) {
                const updatedChannel = await this.cs.updateChannel(this.channel, {
                    ...changedData,
                    restart_confirmed: false
                });
                const showPopupMessageDialog = updatedChannel;
                // Restart Notice
                if (showPopupMessageDialog === true) {
                    await this.modalService.confirm(
                        "SAVE_RESTART",
                        "CHANNEL",
                        async () => {
                            const updateAndRestartChannel = await this.cs.updateChannel(this.channel, {
                                ...changedData,
                                restart_confirmed: true
                            });
                            if (updateAndRestartChannel) {
                                this.saving = false;
                                this.mixpanelService.sendEvent("update & restart adaptive channel", {
                                    updated: Object.keys(changedData)
                                });
                                this.router.navigate(
                                    urlBuilder.getRegularChannelUrl(
                                        this.channel.id,
                                        Constants.urls.channelTypes.adaptive,
                                        model.name
                                    )
                                );
                            } else this.saving = false;
                        },
                        model.name
                    );
                    this.saving = false;
                } else if (updatedChannel) {
                    this.saving = false;
                    this.mixpanelService.sendEvent("update adaptive channel", {
                        updated: Object.keys(changedData)
                    });
                    this.router.navigate(
                        urlBuilder.getRegularChannelUrl(
                            this.channel.id,
                            Constants.urls.channelTypes.adaptive,
                            model.name
                        )
                    );
                } else this.saving = false;
            } else {
                this.saving = false;
                this.router.navigate(
                    urlBuilder.getRegularChannelUrl(this.channel.id, Constants.urls.channelTypes.adaptive, model.name)
                );
            }
        } else {
            const result = await this.cs.addChannel(model, model.type);
            if (result) {
                this.saving = false;
                this.mixpanelService.sendEvent("create adaptive channel");
                this.router.navigate(
                    urlBuilder.getRegularChannelUrl(result.id, Constants.urls.channelTypes.adaptive, model.name)
                );
            } else this.saving = false;
        }
    }

    compareBitrates(existingChannel, model) {
        const reducedOriginal = _.map(existingChannel.bitrates, bitrate => {
            return _.pick(bitrate, "profile_id", "source_id", "name", "kbps");
        });
        if (_.isEqual(reducedOriginal, model.bitrates)) {
            return false;
        } else {
            return model.bitrates;
        }
    }

    cancel() {
        if (!this.isEdit && !this.isClone) return this.router.navigate([Constants.urls.channels]);

        this.router.navigate(
            urlBuilder.getRegularChannelUrl(
                this.channel.id,
                Constants.urls.channelTypes.adaptive,
                this.existingChannel.name
            )
        );
    }

    back() {
        this.location.back();
    }

    async reloadBroadcasterTargets(broadcasterClusterId) {
        this.channel.broadcaster_id = null;
        this.getClusterChannelNames(broadcasterClusterId);
        await this.getTargetBroadcasters(broadcasterClusterId);
        this.getClusterDNSPrefix(broadcasterClusterId);
        this.channel.broadcaster_id = -1;
    }

    getClusterChannelNames(id: number) {
        const filteredChannels = _.filter(this.channels, channel => {
            if (this.existingChannel && this.existingChannel.mediaconnect) return channel.mediaconnect;
            else {
                if (channel.adaptive || channel.delivery) return id === channel.broadcaster_cluster_id;
                return false;
            }
        });
        let filteredChannelNames = _.map(filteredChannels, "name");

        if (this.isEdit) filteredChannelNames = _.without(filteredChannelNames, this.channelName);

        this.channelNames = filteredChannelNames;
    }

    getClusterDNSPrefix(id: number) {
        const cluster = this.clusterService.getCachedCluster(null, id);
        if (cluster) this.clusterDNSPrefix = cluster.dns_prefix;
    }

    async getTargetBroadcasters(id: number) {
        this.targetBXsLoading = true;
        this.targetBXs = this.targetBXsBase;
        if (!id) {
            this.targetBXsLoading = false;
            return;
        }

        try {
            const broadcasters = await this.broadcastersService.refreshBroadcasters(id, true).toPromise();
            if (broadcasters && broadcasters.length > 0) {
                this.targetBXs = this.targetBXsBase.concat(
                    _.map(broadcasters, broadcaster => {
                        return {
                            id: broadcaster.id,
                            name: broadcaster.name,
                            type: "broadcaster",
                            generalStatus: broadcaster.generalStatus
                        };
                    })
                );
            }
        } catch (e) {
            // eslint-disable-next-line no-console
            console.log("error", e);
        }

        this.targetBXsLoading = false;
    }

    selectAdaptiveSource(source: Source) {
        this.selectedBitrates.push({
            profile: null,
            profile_id: null,
            source,
            source_id: source.id,
            name: source.name,
            kbps: source.status && source.status.bitrate ? Math.round(source.status.bitrate) : null
        });

        this.sources = this.sources.filter(s => {
            return s.id !== source.id;
        });

        this.autoPullCheck();
    }

    deselectAdaptiveSource(bitrate: Bitrate) {
        this.sources.push(bitrate.source);
        this.sources = this.sharedService.sort(this.sources || [], "name", "asc");
        this.selectedBitrates = this.selectedBitrates.filter(b => {
            return b.source_id !== bitrate.source_id;
        });

        this.autoPullCheck();
    }

    autoPullCheck(): boolean {
        this.autoPullDisabled = _.some(this.selectedBitrates, b => b.source.disable_autopull);
        return this.autoPullDisabled;
    }

    getSourceBroadcasterClusterID(id: number) {
        if (!this.sources) return null;
        if (this.sources.length === 0) return null;
        if (this.sources.find(s => s.id === id)) return this.sources.find(s => s.id === id).broadcaster_cluster_id;
        else return null;
    }

    alternativeChannelFilter(channel: AdaptiveChannel) {
        return channel.adaptive && channel.is_transcoding === 0;
    }
}
